export const useSlideOver = defineStore('slider', () => {
  const active = ref(false)

  const setSlideOver = (val: boolean) => {
    active.value = val
  }

  return {
    active,
    setSlideOver,
  }
})
