import { parseISO } from 'date-fns'
import type { Database } from '~/types/supabase'

export const useNovelSearch = defineStore('novelSearch', () => {
  const toast = useToast()
  const sb = useSupabaseClient<Database>()

  const novels = ref<Novel[] | undefined>(undefined)

  const search = async ({ keyword }: { keyword: string }) => {
    novels.value = []

    try {
      const rawKeyword = keyword.replace(/[^a-zA-Z0-9 ]/g, '').split(' ').filter(val => val !== '')

      let cleanedWord = ''
      if (rawKeyword.length > 1) {
        cleanedWord = rawKeyword.map(val => `'${val}'`).join(' & ')
      } else {
        cleanedWord = `'${rawKeyword.join('')}'`
      }

      const res = await sb.from('novels')
        .select('*')
        .textSearch('title', cleanedWord)

      if (res.error) {
        throw res.error
      }

      novels.value = res.data.map(val => ({
        associatedNames: val.associated_names,
        genre: val.genre,
        description: val.description,
        id: val.id,
        image: val.image,
        tags: val.tags,
        title: val.title,
        createdAt: parseISO(val.created_at),
      } as Novel))
    } catch (error) {
      toast.add({
        title: 'Failed to search',
        description: String(error),
        color: 'red',
      })
    }
  }

  return {
    // state
    novels,

    // actions
    search,
  }
})
