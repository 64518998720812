import type { Database } from '~/types/supabase'

export const useAuthUser = defineStore('authUser', () => {
  const supabase = useSupabaseClient<Database>()
  const user = useSupabaseUser()

  const isMenuForgotPassword = ref(false)

  const status = ref({
    pending: false,
    action: '',
  })

  const userBalance = ref({
    balance: 0,
  })

  const getBalance = async () => {
    const { data } = await supabase.from('credits').select('balance').single()
    return data?.balance || 0
  }

  if (user.value) {
    getBalance().then((balance) => {
      userBalance.value.balance = balance
    })
  }

  watch(user, async (value) => {
    if (value) {
      const balance = await getBalance()
      userBalance.value.balance = balance
    }
  })

  const loading = computed(() => ({
    signIn: status.value.pending && status.value.action === 'signIn',
    signOut: status.value.pending && status.value.action === 'signOut',
    forgotPassword: status.value.pending && status.value.action === 'forgotPassword',
  }))

  const hasPermissionEditNovel = computed(() => {
    return (novel: Novel | null | undefined) => {
      if (!novel || !user.value) return false
      if (user.value.role === 'admin') return true
      if (novel.createdByUserId === user.value.id) return true
      return false
    }
  })

  const hasPermissionEditChapter = computed(() => {
    return (chapter: Chapter | null | undefined) => {
      if (!chapter || !user.value) return false
      if (user.value.role === 'admin') return true
      if (chapter.createdByUserId === user.value.id) return true
      return false
    }
  })

  const isLoggedIn = computed(() => {
    return !!user.value
  })

  const setStatusPending = (action: string, pending: boolean) => {
    status.value = {
      pending,
      action,
    }
  }

  const credentials = ref({
    email: '',
    password: '',
  })

  const authMessage = ref({
    success: '',
    error: '',
  })

  const resetMessage = () => {
    authMessage.value = {
      success: '',
      error: '',
    }
  }

  const baseURL = useRuntimeConfig().public.baseUrl

  const forgotPassword = async () => {
    resetMessage()
    setStatusPending('forgotPassword', true)
    const { error } = await supabase.auth.resetPasswordForEmail(credentials.value.email, {
      redirectTo: `${baseURL}/auth/confirm`,
    })
    setStatusPending('forgotPassword', false)

    if (error) {
      authMessage.value.error = error.message
      return
    }

    authMessage.value.success = 'Please check your email for the password reset link.'
  }

  const signIn = async () => {
    resetMessage()
    setStatusPending('signIn', true)

    const { error } = await supabase.auth.signInWithPassword({
      email: credentials.value.email,
      password: credentials.value.password,
    })

    setStatusPending('signIn', false)

    if (error) {
      authMessage.value.error = error.message
    }
  }

  const signOut = async () => {
    setStatusPending('signOut', true)
    await supabase.auth.signOut()
    setStatusPending('signOut', false)
  }

  const toggleMenuForgotPassword = (val: boolean) => {
    isMenuForgotPassword.value = val
  }

  const refreshBalance = () => {
    getBalance().then((balance) => {
      userBalance.value.balance = balance
    })
  }

  return {
    // state
    user,
    userBalance,
    credentials,
    authMessage,
    status,
    isMenuForgotPassword,

    // computed
    loading,
    isLoggedIn,
    hasPermissionEditNovel,
    hasPermissionEditChapter,

    // actions
    forgotPassword,
    signIn,
    signOut,
    resetMessage,
    toggleMenuForgotPassword,
    refreshBalance,
  }
})
