/* eslint-disable */
export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.env.DEV) return

  (function (c: { [key: string]: any }, l: Document, a: string, r: string, i: string, t: HTMLScriptElement, y: Node | null) {
    c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) }
    t = l.createElement('script'); t.async = true; t.src = 'https://www.clarity.ms/tag/' + i
    y = l.getElementsByTagName(r)[0]; y?.parentNode?.insertBefore(t, y)
  })(window, document, 'clarity', 'script', 'jpeb4fgpuu', null!, null!)
})
