import type { Database } from '~/types/supabase'

export const useProfile = defineStore('profile', () => {
  const user = useSupabaseUser()
  const supabase = useSupabaseClient<Database>()
  const statusFetch = ref({
    pending: false,
    action: '',
  })
  const statusEdit = ref({
    success: '',
    error: '',
  })

  const name = ref('')
  const email = computed(() => user.value?.email || '')

  const resetStatusEdit = () => {
    statusEdit.value = {
      success: '',
      error: '',
    }
  }

  const getProfile = async () => {
    if (!user.value) return

    statusFetch.value.pending = true
    const { data, error } = await supabase
      .from('profiles')
      .select('name')
      .eq('user_id', user.value.id)
      .single()
    if (error) {
      console.error(error)
      return
    }

    name.value = data?.name || ''

    statusFetch.value.pending = false
    statusFetch.value.action = 'finish'

    return
  }

  const setProfile = async (inputName: string) => {
    if (!user.value) return

    const { error } = await supabase
      .from('profiles')
      .update({ name: inputName })
      .eq('user_id', user.value.id)
    if (error) {
      statusEdit.value.error = error.message
      return
    }

    name.value = inputName
    statusEdit.value.success = 'Success update profile'
    return
  }

  watch(user, async (value) => {
    if (value) {
      await getProfile()
    }
  })

  return {
    statusFetch,
    statusEdit,
    name,
    email,

    // actions
    getProfile,
    setProfile,
    resetStatusEdit,
  }
})
